import React, { FunctionComponent } from 'react';
import { IContentfulSection } from '@belong/types';
import { logger } from '@belong/logging/logger';
import sections from './bootstrap';

const NoopView = () => null;
/**
 * Dynamic Section renderer
 * @param data - the Contentful data for the section
 */

const ContentfulSection: FunctionComponent<IContentfulSection> = ({
  contentType = 'missing-content-type',
  ...props
}) => {
  if (!sections[contentType]) {
    // unknown section or missing view
    logger.error(`No view defined for '${contentType}'`);
    return <NoopView />;
  }

  const Component = sections[contentType];
  return <Component {...props} />;
};

ContentfulSection.displayName = 'ContentfulSection';

export default ContentfulSection;
