import React from 'react';
import ContentfulSection from './ContentfulSection';

interface IContentfulSection {
  sections: any[];
}

const ContentfulLayout: React.FunctionComponent<IContentfulSection> = ({ sections = [] }) => (
  <>
    {sections.map((section, idx) => (
      <ContentfulSection key={section.id || `${section.contentType}-${idx}`} {...section} />
    ))}
  </>
);

export default ContentfulLayout;
